import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
const Avails = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Avails.vue')
const IrisUsers = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/IrisUsers.vue')
const BillingEntities = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/BillingEntities.vue')
const SiteNames = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/SiteNames.vue')
const UnmappedAdUnits = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/UnmappedAdUnits.vue')
const ExternalCostMap = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/ExternalCostMap.vue')
const SnCostMap = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/SnCostMap.vue')
const ManageCostMaps = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/ManageCostMaps.vue')
const IgnoredAdUnits = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/IgnoredAdUnits.vue')
const CleanSiteMap = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/CleanSiteMap.vue')
const ManageUsers = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/ManageUsers.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')
const PreIrisAdUnits = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/PreIrisAdUnits.vue')
const ExpandedIrisData = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/ExpandedIrisData.vue')
const StatusCodes = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/StatusCodes.vue')
const SiteSegmentMap = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/SiteSegmentMap.vue')
const SegmentInsights = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/SegmentInsights.vue')

// Pages
import EditUser from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import CreateUser from 'src/components/Dashboard/Views/Pages/CreateUser.vue'
import CreateMundial from 'src/components/Dashboard/Views/Pages/CreateMundial.vue'
import TimeLine from 'src/components/Dashboard/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Reset from 'src/components/Dashboard/Views/Pages/Reset.vue'
import NewPass from 'src/components/Dashboard/Views/Pages/NewPass.vue'
import Register from 'src/components/Dashboard/Views/Pages/Register.vue'
import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'

// Components pages
import Buttons from 'src/components/Dashboard/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Dashboard/Views/Components/GridSystem.vue'
import Panels from 'src/components/Dashboard/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Dashboard/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Dashboard/Views/Components/Notifications.vue'
import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'
import Typography from 'src/components/Dashboard/Views/Components/Typography.vue'

// Forms pages
const RegularForms  = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/RegularForms.vue')
const ExtendedForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ExtendedForms.vue');
const ValidationForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ValidationForms.vue')
const Wizard = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/Wizard.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');
const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/ExtendedTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/PaginatedTables.vue');
// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/GoogleMaps.vue')
const FullScreenMap = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/FullScreenMap.vue')
const VectorMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/VectorMapsPage.vue');

// Calendar
import Calendar from 'src/components/Dashboard/Views/Calendar/CalendarRoute.vue'
// Charts
const Charts = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Charts.vue')

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }]
}

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: EditUser
    },
    {
      path: 'createuser',
      name: 'New User',
      component: CreateUser
    },
    {
      path: 'createmundial',
      name: 'New Mundial',
      component: CreateMundial
    },
    {
      path: 'manageusers',
      name: 'Manage Users',
      component: ManageUsers
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login,
}

let resetPage = {
  path: '/resetpass',
  name: 'Reset',
  component: Reset
}

let newPassPage = {
  path: '/setpassword',
  name: 'NewPass',
  component: NewPass
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/mundial/overview',
    //redirect: '/login',
    children: [
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  {
  	path: "/index.html",
  	redirect: "/"
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  resetPage,
  newPassPage,
  registerPage,
  lockPage,
  {
    path: '/mundial',
    component: DashboardLayout,
    redirect: '/mundial/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview,
      },
      {
        path: 'avails',
        name: 'Avails',
        component: Avails,
      },
      {
        path: 'billingentities',
        name: 'BE',
        component: BillingEntities,
      },
      {
        path: 'sitenames',
        name: 'SN',
        component: SiteNames,
      },
      {
        path: 'sitesegmentmap',
        name: 'SiteSegment',
        component: SiteSegmentMap,
      },
      {
        path: 'segmentinsights',
        name: 'SegmentInsights',
        component: SegmentInsights,
      },
      {
        path: 'expandedirisdata',
        name: 'ExpandIris',
        component: ExpandedIrisData,
      },
      {
        path: 'preirisadunits',
        name: 'PreIris',
        component: PreIrisAdUnits,
      },
      {
        path: 'awsstatuscodes',
        name: 'StatusCodes',
        component: StatusCodes,
      },
      //{
        //path: 'irisusers',
        //name: 'IrisUsers',
        //component: IrisUsers,
      //},
      {
        path: 'unmapped',
        name: 'Unmapped',
        component: UnmappedAdUnits,
      },
      {
        path: 'costmap',
        name: 'CostMap',
        component: ExternalCostMap,
      },
      {
        path: 'sncostmap',
        name: 'SnCostMap',
        component: SnCostMap,
      },
      {
        path: 'costmaps',
        name: 'CostMaps',
        component: ManageCostMaps,
      },
      {
        path: 'ignored',
        name: 'Ignored',
        component: IgnoredAdUnits,
      },
      {
        path: 'cleansite',
        name: 'CleanSite',
        component: CleanSiteMap,
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  {path: '*', component: NotFound}
];

export default routes
