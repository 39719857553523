export default [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-layout-11',
    path: '/mundial/overview'
  },
  {
    name: 'Inventory Forecasting',
    icon: 'nc-icon nc-chart-bar-32',
    path: '/mundial/avails'
  },
  {
    name: 'Ad Units',
    icon: 'nc-icon nc-tap-01',
    children: [
      {
        name: 'Site Sub-Product Cost Map',
        path: '/mundial/sncostmap',
      },
      {
        name: 'Manage Cost Maps',
        path: '/mundial/costmaps',
      },
      {
        name: 'Manage Ignored',
        path: '/mundial/ignored',
      },
      {
        name: 'Clean Site Map',
        path: '/mundial/cleansite',
      },
    ],
  },
  {
    name: 'Management',
    icon: 'nc-icon nc-settings',
    children: [
      {
        name: 'Billing Entities',
        path: '/mundial/billingentities',
      },
      {
        name: 'Site Names',
        path: '/mundial/sitenames',
      },
      {
        name: 'Site Segment Map',
        path: '/mundial/sitesegmentmap',
      },
    ],
  },
  {
    name: 'Reports',
    icon: 'nc-icon nc-paper',
    children: [
      {
        name: 'AWS Status Codes',
        path: '/mundial/awsstatuscodes',
      },
      {
        name: 'Pre-Iris Ad Units',
        path: '/mundial/preirisadunits',
      },
      {
        name: 'Expanded Iris Data',
        path: '/mundial/expandedirisdata',
      },
      {
        name: 'Segment Insights',
        path: '/mundial/segmentinsights',
      },
    ],
  },
]
